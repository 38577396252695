export default {
  setTitle: (state, title) => state.title = title,
  setProfile: (state, profile) => state.profile = profile,
  setNombre: (state, nombre) => state.nombre = nombre,
  setCertificados: (state, certificados) => state.certificados = certificados,
  setSolicitudes: (state, solicitudes) => state.solicitudes = solicitudes,
  setSolicitud: (state, solicitud) => state.solicitudes.push(solicitud),
  setPermiteSolicitud: (state, permite) => state.permiteSolicitud = permite,
  setTipoIdentificacion: (state, tipoIdentificacion) => state.tipoIdentificacion = tipoIdentificacion,
};
