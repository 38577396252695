import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import getters from './getters';
import session from '@demre/client-common/src/components/jwt-refresher/session';

Vue.use(Vuex);

const state = {
  title: '',
  confirmacion: false,
  notaSimulacion: 0,
  profile: false,
  nombre: '',
  certificados: [],
  solicitudes: [],
  inicioSolicitud: 1966,
  terminoSolicitud: 1987,
  permiteSolicitud: false,
  tipoIdentificacion:''
};

export default new Vuex.Store({
  strict: true,
  state,
  mutations,
  getters,
  modules: {
    session,
  }
});
