import Vue from 'vue';
import vuetify from './plugins/vuetify';
import archivos from '@demre/client-common/src/plugins/archivos';
import store from './store';

Vue.use(archivos);

Vue.config.productionTip = false

async function init() {
  const { default: { request }} = await import('./utils/graphql');
  const { getPayload } = await import('./utils');
  const query = {
    query: `query {
        certificados : certificados {
          idCertificado
          anio
          rendicion
          puntajeNem
          puntajeRkg
          percentilPromPsu
          promPsu
          pruebas {
            glosa
            indObligatoria
            puntaje
          }
          tipoCertificado {
            idTipoCertificado
            valor
          }
          bateria {
            glosa
            descripcion
          }
        }
        persona {
          paterno
          materno
          nombres
          tipoIdentificacion
        }
        solicitudes: certificadosSolicitudByTipoNumero {
          idSolicitud
          fechaIngreso
          anioCertificado
          idEstado
        }
        permiteSolicitud
      }`
  };
  const { certificados, persona, solicitudes, permiteSolicitud } = await request(query);
  store.commit('setNombre', `${persona.nombres} ${persona.paterno} ${persona.materno}`.trim());
  store.commit('setCertificados', certificados);
  store.commit('setPermiteSolicitud', permiteSolicitud);
  store.commit('setTipoIdentificacion', persona.tipoIdentificacion);
  if(solicitudes?.length) {
    store.commit('setSolicitudes', solicitudes);
  }
  const { username } = getPayload();
  window.DATA = {
    profileUrl: `${process.env.VUE_APP_CADES_CLIENT}/profile?appId=${process.env.VUE_APP_CADES_ID}&username=${username}`,
  };
}
store.dispatch('session/load')
  .then(init)
  .then(async () => {
    const { default: App } = await import('./App' /* webpackChunkName: 'certificados' */);
    const { default: router } = await import('./router' /* webpackChunkName: 'router' */);
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
  .catch(async err => {
    const mensaje = err.message;
    const { default: Impedimento } = await import('./Impedimento.vue' /* webpackChunkName: 'impedimento' */);
    new Vue({
      vuetify,
      render: h => h(Impedimento, { props: { mensaje } })
    }).$mount('#app')
  })
